import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { ModalContentComponent } from './modal-content/modal-content.component';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { ErrorHandlerService } from '../../../_services/error-handler.service';
import { MatDividerModule } from '@angular/material/divider';
import { TranslateModule } from '@ngx-translate/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
  selector: 'app-modal',
  standalone: true,
  imports: [
    CommonModule,
    ModalContentComponent,
    MatDialogActions,
    MatDialogModule,
    MatDialogClose,
    MatDividerModule,
    TranslateModule,
    MatProgressSpinnerModule,
  ],
  templateUrl: './modal.component.html',
  styleUrl: './modal.component.scss',
})
export class ModalComponent {
  constructor(
    public dialogRef: MatDialogRef<ModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    readonly errorHandlerService: ErrorHandlerService
  ) {
    this.dialogRef.disableClose = this.data.disableClose;
  }

  close() {
    this.dialogRef.close(true);
  }
}
