import { inject, Injectable } from '@angular/core';
import { ModalComponent } from '../shared/components/modal/modal.component';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerService {
  private dialog = inject(MatDialog);
  private router = inject(Router);

  handleError(error: HttpErrorResponse) {
    console.log(error);

    const errorDescription =
      error?.error?.errorManagement?.errorDescription ?? error.message;
    const errorCode = error?.error?.errorManagement?.errorCode ?? error.status;
    switch (error.status) {
      case 400:
        console.log('Bad request');
        console.log(errorDescription);
        this.openDialog(errorDescription, errorCode, false);
        break;
      case 401:
        console.log('Unauthorized');
        this.router.navigate(['/logout']);
        break;
      case 403:
        console.log('Forbidden');
        this.openDialog('Forbidden', errorCode, true);
        break;
      case 404:
        console.log('Not found');
        console.log(errorDescription);
        this.openDialog(errorDescription, errorCode, false);
        break;
      case 500:
        console.log('Internal server error');
        console.log(error?.error?.errorManagement?.errorDescription ?? error);
        this.openDialog(errorDescription, errorCode, false);
        break;
      case 502:
        console.log('Bad Gateway');
        console.log(error?.error?.errorManagement?.errorDescription ?? error);
        this.openDialog(errorDescription, errorCode, false);
        break;
      default:
        console.log('Something went wrong');
        console.log(errorDescription);
        this.openDialog(errorDescription, errorCode, false);
        break;
    }
  }

  openDialog(
    errorDescription: string,
    errorCode: number,
    disableClose: boolean
  ): void {
    this.dialog.closeAll();
    this.dialog.open(ModalComponent, {
      data: {
        title: 'Error',
        errorCode: errorCode,
        errorDescription: errorDescription,
        isError: true,
        disableClose: disableClose,
      },
    });
  }
}
