<div class="header-style">
  <h2 mat-dialog-title>{{ data.title }}</h2>
  <div>
    @if(data.isError){
    <button
      mat-button
      mat-dialog-close
      class="circle bg-color-blue mr-20"
      (click)="close()"
    >
      <span class="material-symbols-outlined"> close </span>
    </button>
    }
  </div>
</div>
<mat-divider></mat-divider>

<div class="modal-content">
  @if(data.isError){
  <div class="circle bg-color-red mb-15">
    <span class="material-symbols-outlined"> priority_high </span>
  </div>
  <p class="mb-15">{{ "modal.errorCode" | translate }} {{ data.errorCode }}</p>
  <p class="mb-30">
    {{ "modal.errorDescription" | translate }}
    {{ data.errorDescription }}
  </p>
  <p class="mb-40">
    {{ "modal.contactUsMessage" | translate }}
    <a href="mailto:DirectX@ivecogroup.com">{{ "DirectX@ivecogroup.com" }}</a>
  </p>
  } @else {
  <p class="mb-40">{{ data.content }}</p>
  <div class="spinner-style mb-30">
    <mat-spinner></mat-spinner>
  </div>
  }
</div>
